import { render, staticRenderFns } from "./OrganismTeam.vue?vue&type=template&id=a0edebda&scoped=true&"
import script from "./OrganismTeam.vue?vue&type=script&lang=js&"
export * from "./OrganismTeam.vue?vue&type=script&lang=js&"
import style0 from "./OrganismTeam.vue?vue&type=style&index=0&id=a0edebda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0edebda",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsPortfolioAtomTeam: require('/app/components/atoms/portfolio/AtomTeam.vue').default,MoleculesPortfolioMoleculeTeam: require('/app/components/molecules/portfolio/MoleculeTeam.vue').default})
